<template>
  <div class="text-sm">
    <p class="my-4">
      Kies een nieuwe locatie of selecteer een eerder door jouw gebruikte
      locatie
    </p>
    <div v-if="oldLoc && !!Object.keys(oldLoc).length" class="my-4">
      <h2>
        <template v-if="edit">Nieuwe locatie</template>
        <template v-else>Gekozen locatie</template>
      </h2>
      <div>{{ oldLoc.name }} ({{ oldLoc.adress }}, {{ oldLoc.city }})</div>
      <div v-if="edit" class="flex justify-end my-4">
        <button
          class="button button-sm button-primary button-icon"
          @click="$emit('save-location', oldLoc, false)"
        >
          <icon-loader v-if="isLoading" />
          <span class="flex-1">Opslaan</span>
        </button>
      </div>
    </div>
    <div v-else-if="newLoc && !!Object.keys(newLoc).length" class="my-4">
      <h2>
        <template v-if="edit">Nieuwe locatie</template>
        <template v-else>Gekozen locatie</template>
      </h2>
      <div>
        {{ newLoc.address }} {{ newLoc.streetNumber }},
        {{ newLoc.locality }}
      </div>
      <h2>Naam van de locatie</h2>
      <input-field
        id="title"
        v-model="newLoc.name"
        class="mt-2 mb-2"
        default-value="Naam van de locatie"
      />
      <div class="flex justify-end my-4">
        <button
          class="button button-sm button-secondary mr-4"
          @click="newLoc = undefined"
        >
          <icon-loader v-if="isLoading" />
          <span class="flex-1">Annuleren</span>
        </button>
        <button
          class="button button-sm button-primary button-icon"
          :disabled="!newLoc.name"
          @click="$emit('save-location', newLoc, true)"
        >
          <icon-loader v-if="isLoading" />
          <span class="flex-1">Opslaan</span>
        </button>
      </div>
    </div>

    <div v-else class="flex flex-col" :class="edit && 'sm:flex-row'">
      <button
        class="button button-secondary button-icon flex-1 mb-1"
        :class="edit && 'button-sm sm:mb-0 sm:mr-2'"
        @click="showMapModal = true"
      >
        <icon-base size="20"><icon-map /></icon-base>
        <span class="flex-1">Kies nieuwe plek op de kaart</span>
      </button>
      <button
        class="button button-secondary button-icon flex-1 mt-2"
        :class="edit && 'button-sm sm:mt-0 sm:ml-2'"
        @click="showListModal = true"
      >
        <icon-base size="20"><icon-pin /></icon-base>
        <span class="flex-1">Kies eerder gebruikte locatie</span>
      </button>
    </div>

    <!-- location map modal -->
    <modal :show="showMapModal" show-close-icon @close="showMapModal = false">
      <template v-slot:header>Kies plek op de kaart</template>
      <slot>
        <div class="relative h-50v sm:h-50v -m-8">
          <div class="absolute left-0 right-0 h-full">
            <google-maps-location-select ref="mapsLocationSelect" />
          </div>
        </div>
        <div class="mt-14 flex">
          <input-field
            id="search-address"
            v-model="searchAddress"
            default-value="Zoek op adres"
            class="flex-1 mr-4"
          />
          <button class="button button-primary" @click="searchAddressOnMap">
            Zoek
          </button>
        </div>
      </slot>
      <template v-slot:footer>
        <button
          class="button button-primary"
          @click="getLocationData"
          @keyup.enter="getLocationDate"
        >
          Locatie kiezen
        </button>
      </template>
    </modal>

    <!-- location list modal -->
    <modal :show="showListModal" show-close-icon @close="showListModal = false">
      <template v-slot:header>Kies eerder gebruikte locatie</template>
      <slot>
        <template v-if="previousLocations.length">
          <div class="text-sm mb-4 border-b pb-4">
            Selecteer een eerder gebruikte locatie uit onderstaande lijst:
          </div>
          <div>
            <div
              v-for="pl in previousLocations"
              :key="pl.id"
              class="text-sm flex py-2 items-center hover:bg-gray-50 hover:cursor-pointer"
            >
              <icon-base class="mr-4 text-green-600">
                <icon-pin />
              </icon-base>
              <div class="flex-1" @click="$emit('set-previous-location', pl)">
                {{ pl.name }} ({{ pl.adress }}, {{ pl.city }})
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-sm mb-4">
            Je hebt nog geen eerdere locaties opgeslagen
          </div>
        </template>
      </slot>
    </modal>
  </div>
</template>

<script>
import { googleSearchLongAndLatOnMap } from '@/utils/api'
import GoogleMapsLocationSelect from '@/components/GoogleMapsLocationSelect'
import IconBase from '@/components/IconBase'
import IconLoader from '@/components/icons/IconLoader'
import IconMap from '@/components/icons/IconMap'
import IconPin from '@/components/icons/IconPin'
import InputField from '@/components/InputField'
import Modal from '@/components/Modal'
import { LOCATION_REMOVE_BY_ID } from '@/store/actions/location'

export default {
  name: 'LocationPicker',
  components: {
    GoogleMapsLocationSelect,
    IconBase,
    IconLoader,
    IconMap,
    IconPin,
    InputField,
    Modal
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    previousLocations: {
      type: Array,
      default: () => []
    },
    newLocation: {
      type: Object,
      default: () => {}
    },
    oldLocation: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showListModal: false,
      showMapModal: false,
      searchAddress: undefined,
      newLoc: undefined,
      oldLoc: undefined
    }
  },
  watch: {
    oldLocation(newVal) {
      this.showListModal = false
      this.oldLoc = newVal
    }
  },
  mounted() {
    this.newLoc = { ...this.newLocation }
    this.oldLoc = { ...this.oldLocation }
  },
  methods: {
    getLocationData() {
      this.$refs.mapsLocationSelect
        .getPositionData()
        .then((result) => {
          if (
            result.address &&
            result.country &&
            result.locality &&
            result.postalCode &&
            result.streetNumber
          ) {
            this.newLoc = result
            this.showMapModal = false
          } else {
            alert(
              'Adres is incompleet, verplaats the marker om een goed adres te vinden'
            )
          }
        })
        .catch(() => {
          alert('Er is iets mis gegaan met het ophalen adres gegevens')
        })
    },
    async searchAddressOnMap() {
      const addressResponse = await googleSearchLongAndLatOnMap(
        this.searchAddress
      )
      this.$refs.mapsLocationSelect.setLocation(addressResponse.location)
    },
    async removeLocation(id) {
      const removeSuccess = await this.$store.dispatch(
        LOCATION_REMOVE_BY_ID,
        id
      )
      if (removeSuccess) {
        this.$emit('reload-locations')
      } else {
        alert('Locatie verwijderen niet gelukt')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
