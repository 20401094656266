<template>
  <div style="width: 100%; height: 100%; position: relative">
    <gmap-map
      ref="map"
      :center="mapCenter"
      :zoom="zoom"
      :options="{
        zoomControl: true,
        fullscreenControl: false,
        zoomControlOptions: {
          position: 3
        }
      }"
      style="height: 100%; width: 100%"
      @center_changed="updateCenter"
    />
    <div class="center-marker">
      <div class="center-top-box">
        <i class="fas fa-map-marker-alt" />
      </div>
      <div class="center-bottom-box" />
    </div>
  </div>
</template>

<script>
import config from '@/utils/config'
import axios from 'axios'

export default {
  name: 'GoogleMapsLocationSelect',
  data() {
    return {
      zoom: 10,
      mapCenter: { lat: 52.09129938255362, lng: 5.117693057604256 },
      reportedMapCenter: { lat: 52.09129938255362, lng: 5.117693057604256 }
    }
  },
  mounted() {
    this.mapToCurrentPosition()
  },
  methods: {
    setLocation(location) {
      // Update the component information and pan to for map
      this.mapCenter = location
      this.reportedMapCenter = location
      this.$refs.map.$mapPromise.then((map) => {
        // Map pan to and set zoom since we have text
        map.panTo(location)
        map.setZoom(15)
      })
    },
    mapToCurrentPosition() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.mapCenter = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        this.zoom = 14
        this.reportedMapCenter = this.mapCenter
      })
    },
    getPositionData: function () {
      const vm = this
      return new Promise(function (resolve, reject) {
        vm.getAddressByLatLng(vm.reportedMapCenter)
          .then(function (addressObject) {
            resolve(addressObject)
          })
          .catch(function (error) {
            reject(error.message)
          })
      })
    },
    updateCenter(latLng) {
      this.reportedMapCenter = {
        lat: latLng.lat(),
        lng: latLng.lng()
      }
    },
    async getAddressByLatLng(latLng) {
      const vm = this
      const mapsAxios = axios.create({
        baseURL: 'https://maps.googleapis.com/maps/api/geocode'
      })

      try {
        const result = await mapsAxios.get('json', {
          params: {
            latlng: latLng.lat + ',' + latLng.lng,
            key: config.googleMapsApiKey
          }
        })
        return vm.simplifyAddressObject(
          latLng,
          result.data.results[0].address_components
        )
      } catch (e) {
        alert('Locatie niet gevonden: ' + e)
        return e
      }
    },
    simplifyAddressObject(latLng, addressComponents) {
      const addressObject = {
        lat: latLng.lat,
        lng: latLng.lng,
        address: null,
        streetNumber: null,
        postalCode: null,
        locality: null,
        country: null
      }

      // loop through object
      for (const property in addressComponents) {
        if (!addressComponents.hasOwnProperty(property)) continue // eslint-disable-line no-prototype-builtins
        const addressComponent = addressComponents[property]

        // Set country
        if (addressComponent.types.indexOf('country') !== -1) {
          addressObject.country = addressComponent.long_name
        }
        // Set locality
        if (addressComponent.types.indexOf('locality') !== -1) {
          addressObject.locality = addressComponent.long_name
        }
        // Set postalcode
        if (addressComponent.types.indexOf('postal_code') !== -1) {
          addressObject.postalCode = addressComponent.long_name
        }
        // Set address
        if (addressComponent.types.indexOf('route') !== -1) {
          addressObject.address = addressComponent.long_name
        }
        // Set street number
        if (addressComponent.types.indexOf('street_number') !== -1) {
          addressObject.streetNumber = addressComponent.long_name
        }
      }
      return addressObject
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.center-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 1;
  display: flex;
  flex-direction: column;

  .center-top-box,
  .center-bottom-box {
    flex: 1;
  }

  .center-top-box {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .fas {
    font-size: 50px;
    color: $ipGreen;
  }
}
</style>
